const apiUrl = process.env.NODE_ENV === "production" ? "https://api.turnario.com" : "http://localhost:3001"

const config = {
  api: {
    url: apiUrl,
  },
  realm: {
    appId: process.env.REACT_APP_REALM_APP_ID || "",
  }
};

export default config;
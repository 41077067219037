export default function Button({
  title,
  onClick,
  disabled,
  className,
}: {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}) {
  const handleOnSubmit = () => {
    onClick();
  };
  return (
    <button className={`Button full-width ${ className }`}
      onSubmit={handleOnSubmit}
      onClick={handleOnSubmit} 
      type="button"
      disabled={disabled}
    >
      { title }
    </button>
  );
}
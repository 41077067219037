import { useEffect, useState } from "react";
import useAuthContext from "../http-api/auth/useAuthContext";
import ShiftOption from "../http-api/shiftOptions/repositories/ShiftOption";
import useShiftOptions from "../http-api/shiftOptions/useShiftOptions";
import Shift from "../http-api/shifts/repositories/Shift";
import useShifts from "../http-api/shifts/useShifts";
import Preview from "./Preview";
import ResultTemplate from "./ResultTemplate";

export default function Result() {
  const { user, accessToken } = useAuthContext();
  const { isLoading: isLoadingOptions, shiftOptions } = useShiftOptions(accessToken);
  const { isLoading: isLoadingShifts, shifts } = useShifts(accessToken);

  const [message, setMessage] = useState("Cargando...");
  const [fileData, setFileData] = useState<string | undefined>(undefined);

  const [isLoadingFile, setIsLoadingFile] = useState(true);
  const isLoading = isLoadingOptions || isLoadingShifts || isLoadingFile;
  const loadingMessage = isLoadingOptions ? "Cargando tipos de turno..." : isLoadingShifts ? "Cargando turnos..." : isLoadingFile ? "Cargando archivo..." : "";

  useEffect(() => {
    if (shifts.length > 0 && shiftOptions.length > 0) {
      buildCSVData(shifts, shiftOptions).then((data) => {
        setFileData(data);
        setIsLoadingFile(false);
      });
    };
  }, [shifts, shiftOptions]);

  useEffect(() => {
    if (!isLoading) {
      setMessage("¡Todo listo para comenzar la descarga!")
    };
  }, [isLoading]);

  return (
    <ResultTemplate 
      message={message} 
      isLoading={isLoading}
      userId={user?.id || ""}
      fileData={fileData || ""}
    >
      <div className="Result">
        { isLoading ? (
          <p>{ loadingMessage }</p>
        ) : (
          <Preview shifts={shifts} shiftOptions={shiftOptions} />
        ) }
      </div>
    </ResultTemplate>
  );
}


const buildCSVData = async (shifts: Shift[], shiftOptions: ShiftOption[]) => {
  const headers = ["Fecha", "Turno", "Notas", "Grupo", "id"];
  const shiftInfo = shifts.map((shift) => {
    const shiftOption = shiftOptions.find((option) => option.id === shift.shiftOptionId);
    return [
      shift.date,
      `${shiftOption?.title} (${shiftOption?.label}) ${shiftOption?.timeInit} - ${shiftOption?.timeEnd}`,
      shift.note,
      shift.groupId,
      shift.id,
    ].join(";");
  });

  const data = [headers.join(";"), ...shiftInfo].join('\n');
  return data;
};
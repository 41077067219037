import React from "react";
import { createContext, useContext } from "react";
import useAuth from "./useAuth";

const AuthContext = createContext<ReturnType<typeof useAuth> | undefined>(undefined);

export const AuthProvider = ({ children }: {
  children: React.ReactNode,
}) => {
  const auth = useAuth();

  return (
    <AuthContext.Provider value={auth}>
      { children }
    </AuthContext.Provider>
  );
}

const useAuthContext = () => {
  const value = useContext(AuthContext)
  if (value === undefined) {
    throw new Error("useDefaultContext must be used within a Provider");
  }
  return value;
};
export default useAuthContext;
import Button from "./Button";
import Profile from "./Profile";

export default function ResultTemplate(props: Props) {

  return (
    <div className="centered-columns">
      <h3>{ props.message }</h3>
      { props.children }
      { !props.isLoading && (
        <>
          <Button title="Descargar turnos" onClick={() => {
            handleDownload(props.fileData, props.userId);
          }} />
        </>
      ) }
      <Profile />
    </div>
  );
}

interface Props {
  message: string;
  isLoading: boolean;
  fileData: string;
  userId: string;
  children: React.ReactNode;
}


const downloadFile = ({ data, fileName, fileType }: {
  data: string;
  fileName: string;
  fileType: string;
}) => {
  const blob = new Blob([data], { type: fileType })

  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt);
  a.remove();
}

const handleDownload = (fileData: string, userId: string) => {
  downloadFile({
    data: fileData,
    fileName: `turnos-usuario-${userId}.csv`,
    fileType: 'text/csv',
  });
};
import ShiftOption, { ShiftOptionShape } from "../http-api/shiftOptions/repositories/ShiftOption";

export default function ShiftOptionView(props: {
  shiftOption?: ShiftOption;
  size?: number;
  showTitle?: boolean;
}) {
  const { showTitle, shiftOption } = props;
  const size = props.size || 32;

  const useImage = shiftOption?.useImage && shiftOption.imageUrl;
  const isRound = shiftOption?.labelShape === ShiftOptionShape.Circle;
  return (

    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 2,
    }}>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        width: size,

        aspectRatio: "1",
        borderRadius: isRound ? size / 2 : 0,

        backgroundColor: useImage ? "transparent" : (shiftOption?.color || "#ddd"),
        backgroundImage: useImage ? `url(${shiftOption.imageUrl})` : undefined,

        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",

      }}>
        { !useImage && (
          <span style={{
            color: shiftOption?.labelColor || "#333",
            fontWeight: 700,
          }}>{ shiftOption?.label }</span>
        ) }
      </div>
      { showTitle && (
        <p>{ shiftOption?.title }</p>
      ) }
    </div>
  );
}
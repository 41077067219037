import { useState } from "react"
import ShiftOption from "../http-api/shiftOptions/repositories/ShiftOption";
import Shift from "../http-api/shifts/repositories/Shift";
import ShiftOptionView from "./ShiftOptionView";

export default function Calendar(props: Props) {
  const [activeDate, setActiveDate] = useState(new Date());
  const isCurrentMonth = (date: Date) => {
    const today = new Date();
    return date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
  };

  const monthName = activeDate.toLocaleString("es-ES", { month: "long" });
  const weekDays = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];

  const days = getMonthDays(activeDate);

  const shiftsForDate = (date: Date) => {
    return props.shifts.filter((shift) => {
      const shiftDate = new Date(shift.date);
      return shiftDate.getDate() === date.getDate() &&
        shiftDate.getMonth() === date.getMonth() &&
        shiftDate.getFullYear() === date.getFullYear();
    });
  };

  return (
    <div className="centered-columns">
      <h2>Calendario</h2>
      <div className="centered-row" style={{
        width: "100%",
      }}>
        <button className="month-navigator" onClick={() => {
          const newDate = new Date(activeDate);
          newDate.setMonth(newDate.getMonth() - 1);
          setActiveDate(newDate);
        }}>{ "←" }</button>
        <h3 className={`calendar-month ${!isCurrentMonth(activeDate) && "month-link"}`} onClick={() => {
          setActiveDate(new Date());
        }}>{ monthName } { activeDate.getFullYear() }</h3>
        <button className="month-navigator" onClick={() => {
          const newDate = new Date(activeDate);
          newDate.setMonth(newDate.getMonth() + 1);
          setActiveDate(newDate);
        }}>{ "→" }</button>
      </div>
      <div style={{
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        width: "90vw",
        maxWidth: 800,
      }}>
        {
          weekDays.map((day) => (
            <div key={day} style={{
              textAlign: "center",
              padding: 8,
              backgroundColor: "#ccc",
            }}>
              { day }
            </div>
          ))
        }
        {
          days.map((day) => (
            <div key={day.day.toISOString()} style={{
              textAlign: "center",
              padding: 8,
              backgroundColor: day.isCurrentMonth ? "#fff" : "#eee",
              aspectRatio: "1/1",
            }}>
              <div className="calendar-day">
                { day.day.getDate() }
                { shiftsForDate(day.day).map((shift) => {
                  const shiftOption = props.shiftOptions.find((option) => option.id === shift.shiftOptionId);
                  return (
                    <ShiftOptionView key={shift.id} shiftOption={shiftOption} showTitle={false} />
                  );
                }) }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

const getMonthDays = (date: Date) => {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const firstDayOfWeek = firstDayOfMonth.getDay();
  const lastDayOfWeek = lastDayOfMonth.getDay();

  const daysInMonth = lastDayOfMonth.getDate();

  const daysBefore = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;
  const daysAfter = lastDayOfWeek === 0 ? 0 : 7 - lastDayOfWeek;

  const days = new Array(daysBefore + daysInMonth + daysAfter).fill(0).map((_, index) => {
    const day = index - daysBefore + 1;
    return {
      day: new Date(date.getFullYear(), date.getMonth(), day),
      isCurrentMonth: day > 0 && day <= daysInMonth,
    };
  });

  return days;
}

interface Props {
  shifts: Shift[];
  shiftOptions: ShiftOption[];
}
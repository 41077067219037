import config from "../../../config";
import ApiRepository from "../../../shared/repositories/ApiRepository";
import ShiftOption from "./ShiftOption";
import ShiftOptionsRepository from "./ShiftOptionsRepository";

const apiUrl = config.api.url;

export default class ApiShiftOptionsRepository extends ApiRepository implements ShiftOptionsRepository {
  constructor(accessToken: string) {
    super(apiUrl, accessToken);
  }

  async getAll() {
    const response = await this.fetchJson("/sync");
    const shiftOptions: ShiftOption[] = response.groups.map((group: any) => {
      const shiftOptions: ShiftOption[] = group.shift_options.map((option: any) => {
        const shiftOption: ShiftOption = {
          id: option.id || "",
          groupId: option.group_id || "",
          shiftType: option.shift_type || 0,
          title: option.title || "",
          label: option.label || "",
          color: option.color || "",
          labelColor: option.label_color || "",
          imageUrl: option.image_url || "",
          useImage: option.use_image || false,
          labelShape: option.label_shape || 0,
          timeInit: option.time_init || "",
          timeEnd: option.time_end || "",
        };
        return shiftOption;
      });
      return shiftOptions;
    }).reduce((prev: ShiftOption[], current: ShiftOption[]) => {
      return prev.concat(current);
    }, []);
    return shiftOptions;
  }
}
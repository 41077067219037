export default interface ShiftOption {
  id: string;
  groupId: string;

  shiftType: number;
  title: string;

  label: string;
  color: string;
  labelColor: string;
  imageUrl: string;
  useImage: boolean;
  labelShape: ShiftOptionShape,

  timeInit: string;
  timeEnd: string;
}

export const enum ShiftOptionShape {
  Unknown,
  Square,
  Circle,
}

export const enum ShiftOptionType {
  Other,
  Work,
  Free,
  Holiday,
}
import React, { useEffect, useState } from 'react';
import useAuthContext, { AuthProvider } from "./http-api/auth/useAuthContext";

import './App.css';
import AppImage from "./components/AppImage";
import Button from './components/Button';
import LoginForm from './components/LoginForm';
import Result from './components/Result';

function App() {
  const [hasStarted, setHasStarted] = useState(false);

  const {
    isAuthenticated: isApiAuthenticated, 
    isLoading: isApiLoading, 
    loginWithEmailAndPassword: loginApi,
  } = useAuthContext();
  
  const handleOnStart = () => {
    setHasStarted(true);
  };

  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
  
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <div className="App">
      <h1>Asistente de descarga</h1>
      <div className='flex-center'>
        <AppImage 
          src='/images/turnariov1_logo.png' 
          alt='Turnario v1'
          size={hasStarted ? 64 : 200}
        />
        
      </div>

      { hasStarted ? (
        <div className='flex-center' style={{
          alignItems: "stretch",
          flexGrow: 1,
        }}>
          { !isApiAuthenticated ? (
            <LoginForm isLoading={isApiLoading} loginWithEmailAndPassword={loginApi} serviceTitle="Turnario (original)" />
          ) : (
            <div className='flex-center' style={{
              flexDirection: "column",
            }}>
              <Result />
            </div>
          ) }
        </div>
      ) : (
        <div style={{
          maxWidth: "600px",
        }}>
          <h2>Descarga tus turnos en un archivo compatible con Excel</h2>
          <h3>Instrucciones:</h3>
          <ol>
            <li>Inicia sesión con el usuario de la cuenta de Turnario original</li>
            <li>Comprueba que los datos parecen correctos</li>
            <li>¡Inicia la descarga!</li>
          </ol>
          <Button title='Comenzar' onClick={handleOnStart}/>
        </div>
      ) }
    </div>
  );
}

function AppWrapper () {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AppWrapper;

import { useState } from "react";
import Button from "./Button";
import FormInput from "./FormInput";

export default function LoginForm(props: {
  serviceTitle?: string;
  loginWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  isLoading: boolean;
}) {
  const { isLoading, loginWithEmailAndPassword } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const disabled = isLoading || email?.length < 4 || 
    password?.length < 3;

  const serviceTitle = props.serviceTitle || "Turnario";

  return (
    <div className="LoginForm">
      <h3>Iniciar sesión en { serviceTitle }</h3>
      <FormInput type="email" placeholder="Correo electrónico" value={email} onChange={(event) => {
        setEmail(event.target.value);
      }} />
      <FormInput type="password" placeholder="Contraseña" value={password} onChange={(event) => {
        setPassword(event.target.value);
      }} />
      <Button title="Iniciar sesión" onClick={() => {
        loginWithEmailAndPassword(email, password);
      }} disabled={disabled} />
      <a className="Link" href="https://app.turnario.com/" target="_blank" rel="noreferrer">
        ¿No recuerdas la contraseña?
      </a>
    </div>
  );
}
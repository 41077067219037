import { useState } from "react";
import repository from "./repositories/ApiV1AuthRepository";
import AuthUser from "./repositories/AuthUser";

export default function useAuth() {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<AuthUser | undefined>(undefined);
  const accessToken = user?.accessToken;
  const isAuthenticated = Boolean(accessToken);

  const loginWithEmailAndPassword = (email: string, password: string) => {
    setIsLoading(true);
    return repository.loginWithEmailAndPassword(email, password)
    .then((user) => {
      setUser({ ...user, password });
    })
    .catch((reason) => {
      alert(reason);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const logout = () => {
    setUser(undefined);
  }

  return ({
    isLoading,
    accessToken,
    user,
    isAuthenticated,
    loginWithEmailAndPassword,
    logout,
  });
}
import config from "../../../config";
import ApiRepository from "../../../shared/repositories/ApiRepository";
import Shift from "./Shift";
import ShiftsRepository from "./ShiftsRepository";

const apiUrl = config.api.url;

export default class ApiShiftsRepository extends ApiRepository implements ShiftsRepository {
  constructor(accessToken: string) {
    super(apiUrl, accessToken);
  }

  async getAll() {
    const json = await this.fetchJson("/shifts");
    const shifts: Shift[] = json.map((item: any) => {
      const shift: Shift = {
        id: item.id || "",
        date: item.date || "",
        note: item.note || "",
        shiftOptionId: item.shift_option_id || "",
        groupId: item.group_id || "",
      };
      return shift;
    })
    return shifts;
  };

  async getById(id: string) {
    return null;
  }

}
import ShiftOption from "../http-api/shiftOptions/repositories/ShiftOption";
import Shift from "../http-api/shifts/repositories/Shift";
import Calendar from "./Calendar";
import ShiftOptionView from "./ShiftOptionView";

export default function Preview(props: Props) {
  const { shifts, shiftOptions } = props;

  return (
    <div className="centered-columns">
      <p>Tipos de turno encontrados: <b>{ shiftOptions.length }</b></p>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
      }}>
        { shiftOptions.map((option) => (
          <ShiftOptionView key={option.id} shiftOption={option} showTitle={true} />              
        )) }
      </div>
      <Calendar shifts={shifts} shiftOptions={shiftOptions} />
      <p>Número de eventos de turno encontrados: <b>{ shifts.length }</b></p>
    </div>
  );
}

interface Props {
  shifts: Shift[];
  shiftOptions: ShiftOption[];
}
import { useEffect, useState } from "react";
import ApiShiftOptionsRepository from "./repositories/ApiShiftOptionsRepository";
import ShiftOption from "./repositories/ShiftOption";

export default function useShiftOptions(accessToken?: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [shiftOptions, setShiftOptions] = useState<ShiftOption[]>([]);

  useEffect(() => {
    if (accessToken) {
      const repository = new ApiShiftOptionsRepository(accessToken);
      setIsLoading(true);
      repository.getAll()
      .then(setShiftOptions)
      .catch((reason) => {
        console.log("Error:", reason);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }

  }, [accessToken]);

  return ({
    isLoading,
    shiftOptions,
  });
}
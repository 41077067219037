import useAuthContext from "../http-api/auth/useAuthContext";
import Button from "./Button";

export default function Profile() {
  const { user, logout, isLoading } = useAuthContext();

  if (isLoading || !user) {
    return <div>Cargando...</div>;
  };

  return (
    <div className="centered-columns border-top">
      <p>Has iniciado sesión como {user?.email}</p>
      { user && user.imageUrl && (
        <img src={user?.imageUrl} alt="User profile" style={{
          width: 64,
          height: 64,
          borderRadius: 32,
        }} />
      ) }
      <p>¿No eres tú?</p>
      <Button title="Cerrar sesión" onClick={() => {
        logout();
      }} className="secondary" />
    </div>
  );
}
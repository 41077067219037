import React from "react";

export default function AppImage(props: {
  src: string;
  alt?: string;
  size?: number;
}) {
  const size = props.size || 256;
  const width = `${size}px`;
  const borderRadius = `${size/8}px`;

  return (
    <img src={props.src} alt={props.alt || "App image"} style={{
      width,
      // maxWidth: "30%",
      height: "auto",
      // border: "2px solid #333",
      borderRadius,
      margin: "16px",
    }} />
  );
}
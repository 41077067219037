import config from "../../../config";
import AuthError from "./AuthError";
import AuthRepository from "./AuthRepository";
import AuthUser from "./AuthUser";

const baseUrl = config.api.url;

const repository: AuthRepository = {
  loginWithEmailAndPassword: async (email: string, password: string) => {
    const accessToken = await fetchAuthToken(email, password);
    return await fetchUser(accessToken);
  },
};

export default repository;

const fetchAuthToken = async (email: string, password: string): Promise<string> => {
  const data = new URLSearchParams({ email, password });
  const response = await fetch(baseUrl + '/authenticate', {
    method: 'POST',
    body: data.toString(),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
  if (!response.ok) {
    await handleError(response);
  }
  const { auth_token }: { auth_token: string } = await response.json();
  return auth_token;
}

const fetchUser = async (accessToken: string): Promise<AuthUser> => {
  const response = await fetch(baseUrl, {
    method: 'GET',
    headers: { 'Authorization': accessToken },
  });
  if (!response.ok) {
    await handleError(response);
  }
  const { id, name, email, avatar_url: imageUrl } = await response.json();
  return ({ id, name, email, accessToken, imageUrl });
}

const handleError = async (response: Response) => {
  let message = 'An error occurred';
  const errors = await response.json();
  switch (response.status) {
    case 401:
      message = "Email y/o contraseña no es correcto";
      break;
    case 422:
      message = Object.entries(errors).map((error: [string, any]) => `${error[0].replace(/^\w/, (c) => c.toUpperCase())}: ${error[1].join(', ')}`).join('\r\n');
      break;
    case 500:
      message = "Error del servidor. Inténtalo de nuevo más tarde.";
      break;
    default:
      message = JSON.stringify(errors);
      break;
  };
  throw new AuthError(message, response.status);
}

export interface SignupParams {
  name: string;
  email: string;
  password: string;
  password_confirmation?: string;
  avatar_url?: string;
}
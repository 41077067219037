export default class ApiRepository {
  constructor(
    readonly apiUrl: string,
    readonly accessToken: string,
  ) {}

  protected async fetchJson(path: string, method: FetchMethod = "GET", body?: any) {
    const response = await fetch(this.apiUrl + path, {
      method,
      headers: {
        "Authorization": this.accessToken,
        "Accept": "application/json",
      },
      body,
    });
    return await response.json();
  }
}

type FetchMethod = "GET" | "POST" | "PUT" | "DELETE";
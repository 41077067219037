import { useEffect, useState } from "react";
import ApiShiftsRepository from "./repositories/ApiShiftsRepository";
import Shift from "./repositories/Shift";

export default function useShifts(accessToken?: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [shifts, setShifts] = useState<Shift[]>([]);

  useEffect(() => {
    if (accessToken) {
      const repository = new ApiShiftsRepository(accessToken);
      setIsLoading(true);
      repository.getAll()
      .then(setShifts)
      .catch((reason) => console.log("Error:", reason))
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, [accessToken]);
  
  return ({
    isLoading,
    shifts,
  });
}